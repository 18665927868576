<template>
  <v-data-table
    class="admin-table"
    :search="search"
    :headers="headers[$i18n.locale]"
    :items="items"
    :no-results-text="$t('table.noData')"
    :items-per-page="10"
    :hide-default-footer="items.length < 10"
  >
    <template v-slot:[`header.actions`]="{ item }">
      <v-menu bottom left :nudge-width="200" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-if="false" :ripple="false" class="dot-menu" icon v-on="on">
            <IconMenu />
          </v-btn>
        </template>

        <slot name="actions">
          <v-list :ripple="false" class="dot-menu">
            <v-list-item :ripple="false" @click="addNewAdmin(item)">
              <v-list-item-title class="menu-title">{{
                $t('profile.admins.menu[0]')
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </slot>
      </v-menu>
    </template>
    <template v-slot:[`item.role`]="{ item }">
      <div>
        {{ $t('table.roles.' + item.role) }}
      </div>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div>
        <v-icon x-small :color="item.status == ACTIVE ? '#00e284' : '#f79520'"
          >mdi-circle</v-icon
        >

        {{ $t('status.' + item.status) }}
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-menu
        v-if="item.status != REVOKED"
        bottom
        left
        :nudge-width="200"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-if="false" :ripple="false" class="dot-menu" icon v-on="on">
            <IconMenu />
          </v-btn>
        </template>

        <v-list :ripple="false" class="dot-menu">
          <v-list-item :ripple="false" @click.stop @click="view(item)">
            <v-list-item-title class="menu-title">{{
              $t('table.menu.view')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :ripple="false"
            v-if="item.status == PENDING_APPROVAL"
            @click.stop
            v-on:click="approve(key)"
          >
            <v-list-item-title class="menu-title">{{
              $t('table.menu.approve')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :ripple="false"
            v-if="item.status == APPROVED"
            @click.stop
            v-on:click="revoke(key)"
          >
            <v-list-item-title class="menu-title">{{
              $t('table.menu.revoke')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :ripple="false"
            v-if="
              item.status == PENDING_APPROVAL || item.status == WAITING_WALLET
            "
            @click.stop
            v-on:click="cancel(key)"
          >
            <v-list-item-title class="menu-title">{{
              $t('table.menu.cancel')
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import IconMenu from '../../assets/icon-dot-menu';
export default {
  name: 'AdminTable',
  created() {},
  components: {
    IconMenu,
  },
  methods: {
    addNewAdmin() {
      this.$emit('addNewAdmin');
    },
    approve(key) {
      this.$emit('approve', key);
    },
    revoke(key) {
      this.$emit('revoke', key);
    },
    cancel(key) {
      this.$emit('cancel', key);
    },
  },
  props: {
    search: {},
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
  },
};
</script>

<style lang="scss">
.v-data-table.admin-table .v-data-table__wrapper {
  box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;

  & table > tbody > tr:hover {
    background-color: var(--white) !important;
  }
  & table tbody > tr > td,
  & table thead > tr > th,
  & table tfoot > tr > td {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }

  & tr > td {
    svg {
      margin-bottom: -5px;
      margin-right: 4px;
    }
  }
  & table thead > tr > th:last-child,
  & table tbody > tr:not(.v-data-table__empty-wrapper) > td:last-child {
  }
  & table tbody > tr > td:last-child {
  }
  & table thead tr > th {
    font-weight: 600;
    max-height: 48px;
  }

  th {
    padding: 0 16px;
  }
}
.admin-table.v-data-table {
  margin-top: 30px;
}
</style>
